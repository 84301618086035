import { createSlice } from '@reduxjs/toolkit';
import sum from 'lodash/sum';
import uniqBy from 'lodash/uniqBy';
// utils
import axios from '../../utils/axios';
//
import { dispatch } from '../store';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  isLoadingActivities: false,
  error: null,
  activities: [],
  activity: {
    exercises: [],
  },
  activityStats: {},
  sortBy: null,
  actualExercise: {
    isFirstQuestionOfStep: false,
    exerciseType: '',
    activeStep: 0,
    isOptionSelected: false,
    isConfirmedOption: false,
    phraseSimilarity: 0,
    optionSelected: '',
    questions: [],
    actualQuestion: null,
    questionsDone: null,
    videoClassUrl: '',
  },
};

const slice = createSlice({
  name: 'activity',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    startLoadingActivities(state) {
      state.isLoadingActivities = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET PRODUCTS
    getActivitiesSuccess(state, action) {
      state.isLoadingActivities = false;
      state.activities = action.payload;
    },

    // ADD EXERCISE ATTEMPT
    addExerciseAttemptSuccess(state, action) {
      state.isLoading = false;
    },

    // GET PRODUCT
    getProductSuccess(state, action) {
      state.isLoading = false;
      state.activity = action.payload;
    },

    resetActivity(state) {
      state.activity = null;
      state.actualExercise = {
        exerciseType: '',
        activeStep: 0,
        questionsDone: 0,
        isOptionSelected: false,
        isConfirmedOption: false,
        questions: [],
        actualQuestion: null,
      };
    },

    setActivityStats(state, action) {
      state.activityStats = action.payload.activitiesStats.find(
        (activityStats) => activityStats.activityId === action.payload.activity
      );
    },

    setExerciseType(state, action) {
      state.actualExercise.exerciseType = action.payload;
    },

    getActivitySuccess(state, action) {
      state.isLoading = false;
      state.activity = action.payload;

      state.actualExercise.activeStep = 0;
      state.actualExercise.questionsDone = 0;

      switch (state.actualExercise.exerciseType) {
        case 'grammar': {
          console.log('grammar');
          state.activity.exercises = state.activity.grammarExercises;
          state.actualExercise.questions = state.activity.grammarExercises.slice(0, 4);
          state.actualExercise.actualQuestion = state.actualExercise.questions[0];
          state.actualExercise.exerciseType = 'grammar';
          break;
        }
        case 'vocabulary': {
          console.log('vocabulary');
          state.activity.exercises = state.activity.vocabularyExercises;
          state.actualExercise.questions = state.activity.vocabularyExercises.slice(0, 4);
          state.actualExercise.actualQuestion = state.actualExercise.questions[0];
          state.actualExercise.exerciseType = 'vocabulary';
          break;
        }

        case 'pronunciation': {
          console.log('pronunciation');
          state.activity.exercises = state.activity.pronunciationExercises;
          state.actualExercise.questions = state.activity.pronunciationExercises.slice(0, 4);
          state.actualExercise.actualQuestion = state.actualExercise.questions[0];
          state.actualExercise.exerciseType = 'pronunciation';
          break;
        }

        case 'challenge': {
          console.log('challenge');
          state.activity.exercises = state.actualExercise.questions[0];
          state.actualExercise.questions = state.activity.challenges[0].exercises.slice(0, 4);
          state.actualExercise.actualQuestion = state.actualExercise.questions[0];
          state.actualExercise.exerciseType = 'challenge';
          state.actualExercise.videoClassUrl = state.activity.challenges[0].videoUrl;
          break;
        }

        default:
      }
    },

    selectExerciseOption(state, action) {
      state.actualExercise.isOptionSelected = true;
      state.actualExercise.optionSelected = action.payload;
    },

    confirmOption(state, action) {
      state.actualExercise.isConfirmedOption = true;
      state.actualExercise.questionsDone += 1;
    },

    confirmPhrase(state, action) {
      state.actualExercise.isConfirmedOption = true;
      state.actualExercise.questionsDone += 1;
      state.actualExercise.phraseSimilarity = action.payload;
    },

    onBackStep(state) {
      state.checkout.activeStep -= 1;
    },

    onBackToStart(state) {
      state.actualExercise.activeStep = 0;
      state.actualExercise.questionsDone = 0;
      state.actualExercise.isOptionSelected = false;
      state.actualExercise.isConfirmedOption = false;
      state.actualExercise.optionSelected = '';
      state.actualExercise.phraseSimilarity = 0;
    },

    resetStep(state) {
      state.actualExercise.isConfirmedOption = false;
      state.actualExercise.isOptionSelected = false;
      state.actualExercise.isFirstQuestionOfStep = true;

      switch (state.actualExercise.exerciseType) {
        case 'grammar':
          state.actualExercise.questions = state.activity.grammarExercises.slice(
            state.actualExercise.questionsDone,
            state.actualExercise.questionsDone + 4
          );
          state.actualExercise.actualQuestion = state.actualExercise.questions[0];
          break;

        case 'vocabulary':
          state.actualExercise.questions = state.activity.vocabularyExercises.slice(
            state.actualExercise.questionsDone,
            state.actualExercise.questionsDone + 4
          );
          state.actualExercise.actualQuestion = state.actualExercise.questions[0];
          break;

        case 'pronunciation':
          state.actualExercise.questions = state.activity.pronunciationExercises.slice(
            state.actualExercise.questionsDone,
            state.actualExercise.questionsDone + 4
          );
          state.actualExercise.actualQuestion = state.actualExercise.questions[0];
          break;

        default:
          break;
      }
      state.actualExercise.activeStep = 0;
    },

    onNextStep(state) {
      state.actualExercise.activeStep += 1;
      state.actualExercise.isConfirmedOption = false;
      state.actualExercise.isOptionSelected = false;

      switch (state.actualExercise.exerciseType) {
        case 'grammar':
          state.actualExercise.actualQuestion =
            state.activity.grammarExercises[
              state.actualExercise.questionsDone === 0 ? 1 : state.actualExercise.questionsDone
            ];
          break;

        case 'vocabulary':
          state.actualExercise.actualQuestion =
            state.activity.vocabularyExercises[
              state.actualExercise.questionsDone === 0 ? 1 : state.actualExercise.questionsDone
            ];
          break;

        case 'pronunciation':
          state.actualExercise.actualQuestion =
            state.activity.pronunciationExercises[
              state.actualExercise.questionsDone === 0 ? 1 : state.actualExercise.questionsDone
            ];
          break;

        case 'challenge':
          state.actualExercise.actualQuestion =
            state.activity.challenges[0].exercises[
              state.actualExercise.questionsDone === 0 ? 1 : state.actualExercise.questionsDone
            ];
          break;

        default:
          break;
      }
    },

    onGotoStep(state, action) {
      const goToStep = action.payload;
      state.checkout.activeStep = goToStep;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const {
  onGotoStep,
  onBackStep,
  resetStep,
  selectActivity,
  onNextStep,
  selectExerciseOption,
  confirmOption,
  confirmPhrase,
  resetActivity,
  setActivityStats,
  onBackToStart,
} = slice.actions;

// ----------------------------------------------------------------------

export function getActivities(exerciseInterestProfile) {
  return async () => {
    dispatch(slice.actions.startLoadingActivities());
    try {
      const response = await axios.get(`/v1/activities`);
      dispatch(slice.actions.getActivitiesSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getActivitiy(activity, activityStats, exerciseType, exerciseInterestProfile) {
  return async () => {
    dispatch(slice.actions.startLoading());
    dispatch(slice.actions.setExerciseType(exerciseType));
    try {
      const response = await axios.get(`/v1/activity`, {
        params: {
          activityId: activity,
          interestProfile: exerciseInterestProfile,
          lastGrammarExercise: activityStats?.lastGrammarExercise,
          lastVocabularyExercise: activityStats?.lastVocabularyExercise,
          lastPronunciationExercise: activityStats?.lastPronunciationExercise,
        },
      });
      dispatch(slice.actions.getActivitySuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      console.log(error);
    }
  };
}

export function addExerciseAttemp(student, activity, exercise, selectedOption) {
  return async () => {
    try {
      const response = await axios.post(`v1/students/exercises/attempts`, {
        student,
        activity,
        exercise,
        selectedOption,
      });
      dispatch(slice.actions.addExerciseAttemptSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
