import PropTypes from 'prop-types';
// form
import { Controller, useFormContext } from 'react-hook-form';
// @mui
import { FormControlLabel, FormHelperText, Radio, RadioGroup } from '@mui/material';

// ----------------------------------------------------------------------

RHFRadioGroup.propTypes = {
  name: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.string),
  getOptionLabel: PropTypes.arrayOf(PropTypes.string),
  defaultValue: PropTypes.string,
  color: PropTypes.string,
};

export default function RHFRadioGroup({ name, options, getOptionLabel, defaultValue, color, ...other }) {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <div>
          <RadioGroup {...field} row defaultValue={defaultValue ?? ''} {...other}>
            {options &&
              options.map((option, index) => (
                <FormControlLabel
                  key={option}
                  value={option ?? ''}
                  control={<Radio color={color ?? 'primary'} />}
                  label={getOptionLabel?.length ? getOptionLabel[index] : option}
                />
              ))}
          </RadioGroup>

          {!!error && (
            <FormHelperText error sx={{ px: 2 }}>
              {error.message}
            </FormHelperText>
          )}
        </div>
      )}
    />
  );
}
