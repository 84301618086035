// routes
import { PATH_DASHBOARD } from '../../../routes/paths';
// components
import SvgIconStyle from '../../../components/SvgIconStyle';

// ----------------------------------------------------------------------

const getIcon = (name) => <SvgIconStyle src={`/icons/${name}.svg`} sx={{ width: 1, height: 1 }} />;

const ICONS = {
  home: getIcon('ic_home'),
  blog: getIcon('ic_blog'),
  exam: getIcon('ic_exam'),
  feedback: getIcon('ic_feedback'),
  cart: getIcon('ic_cart'),
  chat: getIcon('ic_chat'),
  mail: getIcon('ic_mail'),
  user: getIcon('ic_user'),
  kanban: getIcon('ic_kanban'),
  banking: getIcon('ic_banking'),
  booking: getIcon('ic_booking'),
  invoice: getIcon('ic_invoice'),
  contract: getIcon('ic_contract'),
  calendar: getIcon('ic_calendar'),
  ecommerce: getIcon('ic_ecommerce'),
  analytics: getIcon('ic_analytics'),
  dashboard: getIcon('ic_dashboard'),
  flashcards : getIcon('ic_flashcards'),
};

const navConfig = [
  // GENERAL
  // ----------------------------------------------------------------------
  {
    subheader: 'geral',
    items: [
      { title: 'home', path: PATH_DASHBOARD.general.app, icon: ICONS.home, role: ['user', 'student'], hasRole: false },
      // { title: 'e-commerce', path: PATH_DASHBOARD.general.ecommerce, icon: ICONS.ecommerce },
      // { title: 'analytics', path: PATH_DASHBOARD.general.analytics, icon: ICONS.analytics },
      // { title: 'banking', path: PATH_DASHBOARD.general.banking, icon: ICONS.banking },
      // { title: 'booking', path: PATH_DASHBOARD.general.booking, icon: ICONS.booking, role: ['user', 'student'] },
      {
        title: 'matrícula',
        path: PATH_DASHBOARD.user.matriculate,
        icon: ICONS.dashboard,
        role: ['user'],
        hasRole: true,
      },
    ],
  },

  // MANAGEMENT
  // ----------------------------------------------------------------------
  {
    subheader: 'plataforma',
    items: [
      // EXAM
      {
        title: 'provas',
        path: PATH_DASHBOARD.exam.root,
        icon: ICONS.exam,
        role: ['user', 'student'],
        hasRole: false,
        children: [
          { title: 'nivelamento', path: PATH_DASHBOARD.exam.placement, hasRole: true, role: ['user'] },
          { title: 'escrita', path: PATH_DASHBOARD.exam.listWrittenExam, hasRole: true, role: ['student'] },
          { title: 'oral', path: PATH_DASHBOARD.exam.listOralExam, hasRole: true, role: ['student'] },
        ],
      },

      {
        title: 'atividades',
        path: PATH_DASHBOARD.activity.root,
        icon: ICONS.kanban,
        role: ['student'],
        hasRole: false,
      },

      {
        title: 'flashcards',
        path: PATH_DASHBOARD.flashcards.root,
        icon: ICONS.flashcards,
        role: ['student'],
        hasRole: false,
      },

      {
        title: 'lista de presença',
        path: PATH_DASHBOARD.attendance.root,
        icon: ICONS.calendar,
        role: ['student'],
        hasRole: false,
      },

      {
        title: 'nota semestral',
        path: PATH_DASHBOARD.reportCard.root,
        icon: ICONS.analytics,
        role: ['student'],
        hasRole: false,
      },

      {
        title: 'Feedbacks',
        path: PATH_DASHBOARD.user.feedback,
        icon: ICONS.feedback,
        role: ['student'],
        hasRole: false,
      },

      // // TRIAL LESSON
      // {
      //   title: 'aula experimental',
      //   path: PATH_DASHBOARD.trialLesson.root,
      //   icon: ICONS.calendar,
      //   role: ['user'],
      //   hasRole: true,
      //   children: [{ title: 'agendar', path: PATH_DASHBOARD.trialLesson.signuUp, hasRole: true, role: ['user'] }],
      // },

      // INVOICE
      {
        title: 'parcelas',
        path: PATH_DASHBOARD.invoice.root,
        icon: ICONS.invoice,
        role: ['student'],
        hasRole: true,
        children: [
          { title: 'lista', path: PATH_DASHBOARD.invoice.list, hasRole: true, role: ['student'] },
          // { title: 'details', path: PATH_DASHBOARD.invoice.demoView },
          // { title: 'create', path: PATH_DASHBOARD.invoice.new },
          // { title: 'edit', path: PATH_DASHBOARD.invoice.demoEdit },
        ],
      },

      // E-COMMERCE
      {
        title: 'livros',
        path: PATH_DASHBOARD.eCommerce.root,
        icon: ICONS.cart,
        role: ['student'],
        children: [
          { title: 'comprar', path: PATH_DASHBOARD.eCommerce.shop, hasRole: true, role: ['user', 'student'] },
          { title: 'pedidos', path: PATH_DASHBOARD.eCommerce.orders, hasRole: true, role: ['user', 'student'] },
          // { title: 'product', path: PATH_DASHBOARD.eCommerce.demoView },
          // { title: 'list', path: PATH_DASHBOARD.eCommerce.list },
          // { title: 'create', path: PATH_DASHBOARD.eCommerce.new },
          // { title: 'edit', path: PATH_DASHBOARD.eCommerce.demoEdit },
          // { title: 'checkout', path: PATH_DASHBOARD.eCommerce.checkout },
        ],
      },

      // // BLOG
      // {
      //   title: 'blog',
      //   path: PATH_DASHBOARD.blog.root,
      //   icon: ICONS.blog,
      //   children: [
      //     { title: 'posts', path: PATH_DASHBOARD.blog.posts },
      //     { title: 'post', path: PATH_DASHBOARD.blog.demoView },
      //     { title: 'create', path: PATH_DASHBOARD.blog.new },
      //   ],
      // },

      {
        title: 'contrato',
        path: PATH_DASHBOARD.contract.root,
        icon: ICONS.contract,
        role: ['student'],
        hasRole: false,
      },
    ],
  },

  // // APP
  // // ----------------------------------------------------------------------
  // {
  //   subheader: 'app',
  //   items: [
  //     {
  //       title: 'mail',
  //       path: PATH_DASHBOARD.mail.root,
  //       icon: ICONS.mail,
  //       info: (
  //         <Label variant="outlined" color="error">
  //           +32
  //         </Label>
  //       ),
  //     },
  //     { title: 'chat', path: PATH_DASHBOARD.chat.root, icon: ICONS.chat },
  //     { title: 'calendar', path: PATH_DASHBOARD.calendar, icon: ICONS.calendar },
  //     { title: 'kanban', path: PATH_DASHBOARD.kanban, icon: ICONS.kanban },
  //   ],
  // },
];

export default navConfig;
