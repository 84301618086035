import { createSlice } from '@reduxjs/toolkit';
import sum from 'lodash/sum';
import uniqBy from 'lodash/uniqBy';
// utils
import axios from '../../utils/axios';
//
import { dispatch } from '../store';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  privateClassesCourse: null,
  teachers: [],
  availableDays: [],
  availableHoraries: [],
  teacher: null,
  selectedTeacherId: null,
  selectedDayId: null,
  filters: {
  }
};

const slice = createSlice({
  name: 'courseType',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET PRIVATE CLASSES COURSES
    getPrivateClassesCourseSucess(state, action) {
      state.isLoading = false;
      state.privateClassesCourse = action.payload;
    },

    // GET TEACHERS
    getTeachersSuccess(state, action) {
      state.isLoading = false;
      state.teachers = action.payload;
    },

    // GET SELECTED TEACHER
    getSelectedTeacher(state, action) {
      state.isLoading = false;
      state.selectedTeacherId = action.payload;
    },

    // GET SELECTED DAY
    getSelectedDay(state, action) {
      state.isLoading = false;
      state.selectedDayId = action.payload;
    },

    // GET AVAILABLE DAYS
    getAvailableDays(state, action) {
      state.isLoading = false;

      const privateClassesCourseId = state.privateClassesCourse.id;
      const selectedTeacherId = state.selectedTeacherId;
      const coursesAvailability = state.teachers.find(teacher => 
        teacher.id === selectedTeacherId).coursesAvailability;

      const availableDays = coursesAvailability.find(courseAvailability => 
        courseAvailability.course === privateClassesCourseId).availableDays;

      state.availableDays = availableDays;
    },

    // GET AVAILABLE HORARIES
    getAvailableHoraries(state, action) {
      state.isLoading = false;

      const selectedDayId = state.selectedDayId;
      const availableHoraries = state.availableDays.find(day => 
        day.id === selectedDayId).availableHoraries;

      state.availableHoraries = availableHoraries;
    },

    // MATRICULATE
    matriculateSuccess (state, action) {
      state.hasError = null;
      const matriculateData = action.payload;
      state.isLoading = false;
      // state.events = [...state.events, matriculateData];
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
// export const {} = slice.actions;

// ----------------------------------------------------------------------

export function getPrivateClassesCourse() {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const { data } = await axios.get('/v2/courses/2');

            dispatch(slice.actions.getPrivateClassesCourseSucess(data.data.privateClassesCourse));
            dispatch(slice.actions.getTeachersSuccess(data.data.teachers));
            dispatch(slice.actions.getSelectedTeacher(data.data.teachers[0].id));
            dispatch(slice.actions.getAvailableDays());
            dispatch(slice.actions.getSelectedDay(data.data.teachers[0].coursesAvailability[0].availableDays[0].id));
            dispatch(slice.actions.getAvailableHoraries());
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    }
};

export function getSelectedTeacher(teacherId) {
  return async () => {
      dispatch(slice.actions.startLoading());
      try {
        dispatch(slice.actions.getSelectedTeacher(teacherId));
      } catch (error) {
          dispatch(slice.actions.hasError(error));
      }
  }
};

export function getSelectedDay(dayId) {
  return async () => {
      dispatch(slice.actions.startLoading());
      try {
        dispatch(slice.actions.getSelectedDay(dayId));
      } catch (error) {
          dispatch(slice.actions.hasError(error));
      }
  }
};

export function getAvailableDays() {
  return async () => {
      dispatch(slice.actions.startLoading());
      try {
        dispatch(slice.actions.getAvailableDays());
      } catch (error) {
          dispatch(slice.actions.hasError(error));
      }
  }
};

export function getAvailableHoraries() {
  return async () => {
      dispatch(slice.actions.startLoading());
      try {
        dispatch(slice.actions.getAvailableHoraries());
      } catch (error) {
          dispatch(slice.actions.hasError(error));
      }
  }
};

export function matriculatePrivateClasses(matriculateData) {
  return async () => {
      dispatch(slice.actions.startLoading());
      try {
        const response = await axios.post('/v1/students/matriculate/private-classes', matriculateData);
        dispatch(slice.actions.matriculateSuccess());
      } catch (error) {
          dispatch(slice.actions.hasError(error));
          console.log(error);
      }
  }
};

export function matriculateGroupClasses(matriculateData) {
  return async () => {
      dispatch(slice.actions.startLoading());
      try {
        const response = await axios.post('/v1/students/matriculate/group-classes', matriculateData);
        dispatch(slice.actions.matriculateSuccess());
      } catch (error) {
          dispatch(slice.actions.hasError(error));
      }
  }
};

export function matriculateOnTheGo(matriculateData) {
  return async () => {
      dispatch(slice.actions.startLoading());
      try {
        const response = await axios.post('/v1/students/matriculate/platform', matriculateData);
        dispatch(slice.actions.matriculateSuccess());
      } catch (error) {
          dispatch(slice.actions.hasError(error));
      }
  }
};  

// ----------------------------------------------------------------------
