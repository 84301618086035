import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import sum from 'lodash/sum';
import uniqBy from 'lodash/uniqBy';
// utils
import axiosUp from '../../utils/axios';
//
import { dispatch } from '../store';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  materials: [],
  material: null,
  sortBy: null,
  checkout: {
    activeStep: 0,
    cart: [],
    subtotal: 0,
    total: 0,
    discount: 0,
    shipping: {},
    billing: null,
    addresses: [],
    freight: [],
    payment: {
      method: '',
      card: {},
    },
    apiResponse: {},
  },
  orders: [],
};

const slice = createSlice({
  name: 'material',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET MATERIALS
    getMaterialsSuccess(state, action) {
      state.isLoading = false;
      state.materials = action.payload;
    },

    // GET ORDERS
    getOrdersSuccess(state, action) {
      state.isLoading = false;
      state.orders = action.payload;
    },

    // CALCULATE SHIPPING
    calculateShippingSuccess(state, action) {
      state.isLoading = false;
      state.checkout.freight = action.payload;
    },

    // GET MATERIAL
    getMaterialSuccess(state, action) {
      state.isLoading = false;
      state.material = action.payload;
    },

    //  SORT & FILTER PRODUCTS
    sortByProducts(state, action) {
      state.sortBy = action.payload;
    },

    clearAddressess(state, action) {
      state.checkout.addresses = [];
    },

    filterProducts(state, action) {
      state.filters.gender = action.payload.gender;
      state.filters.category = action.payload.category;
      state.filters.colors = action.payload.colors;
      state.filters.priceRange = action.payload.priceRange;
      state.filters.rating = action.payload.rating;
    },

    // CHECKOUT
    getCart(state, action) {
      const cart = action.payload;

      const subtotal = sum(cart.map((cartItem) => cartItem.value * cartItem.quantity));
      const discount = cart.length === 0 ? 0 : state.checkout.discount;
      const shipping = cart.length === 0 ? {} : state.checkout.shipping;
      const billing = cart.length === 0 ? null : state.checkout.billing;

      state.checkout.cart = cart;
      state.checkout.discount = discount;
      state.checkout.shipping = shipping;
      state.checkout.billing = billing;
      state.checkout.subtotal = subtotal;
      state.checkout.total = subtotal - discount;
    },
    addAddress(state, action) {
      const address = action.payload;
      if (state.checkout.addresses.length === 0) {
        state.checkout.addresses = [...state.checkout.addresses, address];
      } else {
        state.checkout.addresses.map((_address) => {
          const isExisted = _address.zipCode === address.zipCode;
          if (isExisted) {
            return {
              ..._address,
            };
          }
          return _address;
        });
      }
      state.checkout.addresses = uniqBy([...state.checkout.addresses, address], 'zipCode');
    },

    addPayment(state, action) {
      state.checkout.payment = action.payload;
    },

    addCart(state, action) {
      const product = action.payload;
      const isEmptyCart = state.checkout.cart.length === 0;

      if (isEmptyCart) {
        state.checkout.cart = [...state.checkout.cart, product];
      } else {
        state.checkout.cart = state.checkout.cart.map((_product) => {
          const isExisted = _product.id === product.id;
          if (isExisted) {
            return {
              ..._product,
              quantity: _product.quantity + 1,
            };
          }
          return _product;
        });
      }
      state.checkout.cart = uniqBy([...state.checkout.cart, product], 'id');
    },

    deleteCart(state, action) {
      const updateCart = state.checkout.cart.filter((item) => item.id !== action.payload);

      state.checkout.cart = updateCart;
    },

    resetCart(state) {
      state.checkout.activeStep = 0;
      state.checkout.cart = [];
      state.checkout.total = 0;
      state.checkout.subtotal = 0;
      state.checkout.discount = 0;
      state.checkout.shipping = {};
      state.checkout.billing = null;
      state.checkout.freight = [];
      state.checkout.addresses = [];
      console.log('resetCart');
    },

    onBackStep(state) {
      state.checkout.activeStep -= 1;
    },

    onNextStep(state) {
      state.checkout.activeStep += 1;
    },

    onGotoStep(state, action) {
      const goToStep = action.payload;
      state.checkout.activeStep = goToStep;
    },

    increaseQuantity(state, action) {
      const productId = action.payload;
      const updateCart = state.checkout.cart.map((product) => {
        if (product.id === productId) {
          return {
            ...product,
            quantity: product.quantity + 1,
          };
        }
        return product;
      });

      state.checkout.cart = updateCart;
    },

    decreaseQuantity(state, action) {
      const productId = action.payload;
      const updateCart = state.checkout.cart.map((product) => {
        if (product.id === productId) {
          return {
            ...product,
            quantity: product.quantity - 1,
          };
        }
        return product;
      });

      state.checkout.cart = updateCart;
    },

    createBilling(state, action) {
      state.checkout.billing = action.payload;
    },

    applyDiscount(state, action) {
      const discount = action.payload;
      state.checkout.discount = discount;
      state.checkout.total = state.checkout.subtotal - discount;
    },

    applyShipping(state, action) {
      const shipping = action.payload;
      state.checkout.shipping = shipping;
      state.checkout.total = state.checkout.subtotal - state.checkout.discount + Number(shipping.price);
    },

    addApiResponse(state, action) {
      state.apiResponse = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const {
  addAddress,
  getCart,
  addCart,
  addPayment,
  resetCart,
  onGotoStep,
  onBackStep,
  onNextStep,
  deleteCart,
  createBilling,
  applyShipping,
  applyDiscount,
  increaseQuantity,
  decreaseQuantity,
  sortByProducts,
  filterProducts,
  addApiResponse,
  clearAddressess,
} = slice.actions;

// ----------------------------------------------------------------------

export function getOrders() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axiosUp.get('/v1/students/courses/materials/orders');
      dispatch(slice.actions.getOrdersSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getMaterials() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axiosUp.get('/v1/group-classes/materials');
      dispatch(slice.actions.getMaterialsSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getMaterial(name) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axiosUp.get('/v1/group-classes/materials');
      dispatch(slice.actions.getMaterialSuccess(response.data.data.find((material) => material.id === name)));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function calculateShipping(zipCode) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(
        'https://levelup-api-intermediaty.azurewebsites.net/v1/materials/shippings/calculate',
        {
          zipCode,
        }
      );
      dispatch(slice.actions.calculateShippingSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
