import { createSlice } from '@reduxjs/toolkit';
import sum from 'lodash/sum';
import uniqBy from 'lodash/uniqBy';
// utils
import axios from '../../utils/axios';
//
import { dispatch } from '../store';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  isLoadingActivities: false,
  error: null,
  flashcards: [],
  feedbacks: null,
  practiceNow: [],
  newCardsSoon: [],
  learnNew: [],
  memorized: [],
  pending: [],
  currentContext: {},
};

const slice = createSlice({
  name: 'flashcards',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    startLoadingActivities(state) {
      state.isLoadingActivities = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET FLASHCARDS
    getFlashcardsSuccess(state, action) {
      state.isLoading = false;
      state.flashcards = action.payload.flashcards;
      state.feedbacks = action.payload.feedbacksCount;
    },

    // GET CONTEXTS
    getContextsSuccess(state, action) {
      state.isLoading = false;
      state.practiceNow = action.payload.practiceNow;
      state.newCardsSoon = action.payload.newCardsSoon;
      state.learnNew = action.payload.learnNewContext;
      state.memorized = action.payload.memorizedContexts;
      state.pending = action.payload.pendingContexts;
    },

    selectContext(state, action) {
      state.currentContext = action.payload;
    }
  },
});

// Reducer
export default slice.reducer;

// Actions
export const {
  startLoading,
  startLoadingActivities,
  hasError,
  getFlashcardsSuccess,
  getContextsSuccess,
  selectContext
} = slice.actions;

// ----------------------------------------------------------------------

export function getFlashcards() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/v1/flashcards');
      dispatch(slice.actions.getFlashcardsSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getFlashcardsByContext(contextId) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/v1/flashcards/${contextId}`);
      dispatch(slice.actions.getFlashcardsSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getContexts() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/v1/flashcards/contexts');
      dispatch(slice.actions.getContextsSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
